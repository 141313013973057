import ImageCarousel from "../components/ImageCarousel.jsx";
import OurPortfolioProjects from "../components/OurPortfolioProjects.jsx";
import { home_page_carousel } from "../utils/config/site_urls.js";
import ContactUs from "./ContactUs.jsx";
import Testimonials from "../components/Testimonials.jsx";
import HousingAssociationCarousel from "../components/HousingAssociationCarousel.jsx";
import AccreditationsCarousel from "../components/AccreditationsCarousel.jsx";

export default function Home() {
  return (
    <div className="flex flex-col">
      <ImageCarousel urls={home_page_carousel.urls} text={home_page_carousel.text} />
      <OurPortfolioProjects />
      <div className="relative h-40 md:h-64 lg:h-96">
        <div className="absolute top-0 left-0 w-full h-full bg-syracuseorange-100 opacity-50 z-0" />
        <div className="absolute top-0 left-0 w-full h-full">
          <div className="flex w-full h-full text-white items-center text-xl md:text-2xl xl:text-4xl font-semibold justify-center z-40 px-[10%] text-center">
            Building homes for those truly in need
          </div>
        </div>
      </div>
      <HousingAssociationCarousel />
      <Testimonials />
      <ContactUs />
      <AccreditationsCarousel />
    </div>
  );
}
