import { useEffect, useState } from "react";
import Loader from "./Loader";

export default function HubspotForm({ region, portalId, formId, target = "#hubspotForm" }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    const scriptSrc = "https://js-eu1.hsforms.net/forms/embed/v2.js";

    async function loadHubspotForm() {
      // Dynamically load the HubSpot script
      const script = document.createElement("script");
      script.src = scriptSrc;
      script.type = "text/javascript";
      script.async = true;

      script.onload = () => {
        if (window.hbspt && isMounted) {
          window.hbspt.forms.create({
            region,
            portalId,
            formId,
            target,
          });
          setLoading(false);
        } else if (!window.hbspt) {
          console.error("hbspt is not defined after loading script");
        }
      };

      script.onerror = () => {
        console.error("Failed to load the HubSpot script");
      };

      document.body.appendChild(script);
    }

    loadHubspotForm();

    return () => {
      // Cleanup script and mark component as unmounted
      isMounted = false;
      const existingScript = document.querySelector(`script[src="${scriptSrc}"]`);
      if (existingScript) document.body.removeChild(existingScript);
    };
  }, [region, portalId, formId, target]);

  return <>{loading ? <Loader /> : <div id="hubspotForm" className="flex w-full h-full bg-white" />}</>;
}
